import { MenuOption } from "./menu-option";
import { Rankable } from "./rankable";
import {WorkspaceTeamRole} from "../types/workspace.types";
import {WorkspacePermissionUtils} from "../configs/workspace-permission-utils";

export class ActionMenuOption extends MenuOption implements Rankable {

    private _rank: number = WorkspacePermissionUtils._TEAM_ROLE_RANKS.get(WorkspaceTeamRole.MEMBER);

    getRank = (): number => {
        return this._rank;
    }

    setRank = (rank: number): ActionMenuOption => {
        this._rank = rank;
        return this;
    }

}